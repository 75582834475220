import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter);


const routes = [
{
  path: '/',
  name: 'login',
  component: () => import('../components/login.vue')
},
{
  path: '/editarPerfil',
  name: 'editarPerfil',
  component: () => import('../components/editarPerfil.vue')
},
//DASHBOARD
{ 
  path: '/dashboard', component: () => import('../views/Inicio.vue')
},
{ 
  path: '/prueba', component: () => import('../views/formatoValidezAutenticacion.vue')
},
//------------------------------------------------------------------------------------------------------ VISTAS PARA GESTIÓN HUMANA
{ 
  path: '/registroColaborador', component: () => import('../views/gestionHumana/registroColaboradorFormulario.vue')
},
{ 
  path: '/registroContratacion', component: () => import('../views/gestionHumana/registroContratacion.vue')
},
{ 
  path: '/catalogoLicencias', component: () => import('../views/gestionHumana/catalogoLicencias.vue')
},
{ 
  path: '/registroLicencias', component: () => import('../views/gestionHumana/registroLicencias.vue')
},
{ 
  path: '/reporteAsistencia', component: () => import('../views/gestionHumana/asistenciaRecursos.vue')
},
{ 
  path: '/ediciongh', component: () => import('../views/gestionHumana/edicionGH.vue')
},
{ 
  path: '/usuarios', component: () => import('../views/UMG/usuarios.vue')
},
{ 
  path: '/asignacionAdmon', component: () => import('../views/gestionHumana/asignacionAdmon.vue')
},
{ 
  path: '/registroHuellero', component: () => import('../views/gestionHumana/registroHuellero.vue')
},
{
  path: '/informePapeleriaGestionHumana', component: () => import('../views/gestionHumana/informePapeleria.vue')
},
//---------------------------------------------------------------------------------------------------------------VISTAS PARA CAMPO Y CLIMATOLOGÍA
{ 
  path: '/edicionPlanoSiembra', component: () => import('../views/Campo/Edición/planoSiembra.vue')
},
{ 
  path: '/asistencia', component: () => import('../views/climatologia/AsistenciaDos.vue')
},
{ 
  path: '/rangoAsistencias', component: () => import('../views/gestionHumana/rangoAsistencias.vue')
},
{ 
  path: '/climatologiaTemperatura', component: () => import('../views/climatologia/campoTemperatura.vue')
},
{ 
  path: '/resultadosTemperatura', component: () => import('../views/climatologia/resultadosTemperatura.vue')
},
{ 
  path: '/exportarData', component: () => import('../views/climatologia/exportarData.vue')
},
{ 
  path: '/edicionTemperatura', component: () => import('../views/climatologia/edicionTemperatura.vue')
},
{ 
  path: '/estimadoDiario', component: () => import('../views/Campo/estimadoDiario.vue')
},
{ 
  path: '/rendimientosColaboradores', component: () => import('../views/Campo/rendimientoColaboradores.vue')
},
{ 
  path: '/muestreoTrips', component: () => import('../views/Campo/muestreoTrips.vue')
},
{ 
  path: '/alineamientosSupervisores', component: () => import('../views/Campo/alineamientosSupervisores.vue')
},
{
  path: '/colectaMallasSupervisor', component: () => import('../views/Campo/colectaMallasSupervisor.vue')
},
{
  path: '/verificacionColectaMallas', component: () => import('../views/Campo/verificacionColectaMallas.vue')
},
{
  path: '/actividadesCamaRegistro', component: () => import('../views/Campo/registroActividadCama.vue')
},
{ 
  path: '/conteoThripsMonitoreo', component: () => import('../views/Campo/MIPE-MIRFE/MIPE/conteoThripsMonitoreo.vue')
},
{ 
  path: '/monitoreoCampo', component: () => import('../views/Campo/Monitoreo/monitoreoCampo.vue')
},
{ 
  path: '/resultadosMonitoreoCampo', component: () => import('../views/Campo/Monitoreo/resultadosEvaluacionCampo.vue')
},
{ 
  path: '/registroMonitoreoDirecto', component: () => import('../views/Campo/MIPE-MIRFE/MIPE/registroMonitoreoDirecto.vue')
},
{ 
  path: '/controlproduccion40', component: () => import('../views/Campo/Monitoreo/controlProduccion40.vue')
},
//---------------------------------------------------------------------------------------------------------- VISTAS PARA IT
{ 
  path: '/ordenTrabajoMantenimiento', component: () => import('../views/Campo/Mantenimiento/Edición/ordenTrabajo.vue')
},

//---------------------------------------------------------------------------------------------------------- VISTAS PARA FINANZAS
{
  path: '/calculadora', component: () => import('../views/Finanzas/calculadoraCambio.vue')
},
//---------------------------------------------------------------------------------------------------------- VISTAS PARA PLANEACIÓN
{ 
  path: '/realizarAsignacion', component: () => import('../views/Planeación/realizarAsignacion.vue')
},
{ 
  path: '/moverPersonal', component: () => import('../views/Planeación/moverPersonal.vue')
},
{ 
  path: '/estimacionesPlaneacion', component: () => import('../views/Planeación/estimacionesPlaneacion.vue')
},
{ 
  path: '/avancesyrendimientosPlaneacion', component: () => import('../views/Planeación/reportesCampo.vue')
},
{ 
  path: '/actividadesCampo', component: () => import('../views/Planeación/actividadesCampo.vue')
},
{ 
  path: '/muestreoThripsPlaneacion', component: () => import('../views/Planeación/muestreoThripsPlaneacion.vue')
},
{ 
  path: '/alineamientosPlaneacion', component: () => import('../views/Planeación/alineamientosPlaneacion.vue')
},
{ 
  path: '/asignacionAreaFisicaCampo', component: () => import('../views/Planeación/asignacionAreaFisica.vue')
},
{ 
  path: '/edicionRendimientosPlaneacion', component: () => import('../views/Planeación/edicionRendimientos.vue')
},
{ 
  path: '/colectaDeMallasPlaneacion', component: () => import('../views/Planeación/colectaMallasDatos.vue')
},
{ 
  path: '/actividadesCamaPlaneacion', component: () => import('../views/Planeación/actividadesCamaPlaneacion.vue')
},
{ 
  path: '/monitoreoPlacasPlaneacion', component: () => import('../views/Planeación/datosConteoThrips.vue')
},
{ 
  path: '/monitoreoCampoPlaneacion', component: () => import('../views/Planeación/monitoreoCampoPlaneacion.vue')
},
{
  path: '/edicionMonitoreoCampo', component: () => import('../views/Planeación/edicionMonitoreoCalidadCampo.vue')
},
{ 
  path: '/datosMonitoreoDirecto', component: () => import('../views/Planeación/Reportes/reporteMonitoreoDirectoExcel.vue')
},
{ 
  path: '/resultadosCampoPlaneacion', component: () => import('../views/Planeación/resultadosCampoPlaneacion.vue')
},
{ 
  path: '/proyeccionVariedades', component: () => import('../views/Planeación/Reportes/proyeccionVariedad.vue')
},
//---------------------------------------------------------------------------------------------------------- VISTAS PARA POSTCOSECHA
{ 
  path: '/codigobarra', component: () => import('../views/Postcosecha/Prefrio/generarEtiquetas.vue')
},
{
  path: '/asignacionPostcosecha', component: () => import('../views/Postcosecha/asignacionPostcosecha.vue')
},
{ 
  path: '/calidadRecepcionMonitoreo', component: () => import('../views/Postcosecha/controlCalidad/monitoreoCalidadRecepcion.vue')
},
{ 
  path: '/calidadRecepcionDatos', component: () => import('../views/Postcosecha/controlCalidad/datosCalidadRecepcion.vue')
},
{ 
  path: '/fechasActivasCalidadB', component: () => import('../views/Postcosecha/controlCalidadB/fechasCalidadB.vue')
},
{ 
  path: '/monitoreoCalidadB', component: () => import('../views/Postcosecha/controlCalidadB/monitoreoCalidadB.vue')
},
{ 
  path: '/reporteCalidadB', component: () => import('../views/Postcosecha/controlCalidadB/reporteCalidadB.vue')
},
{ 
  path: '/filtrosCalidadB', component: () => import('../views/Postcosecha/controlCalidadB/filtroCalidadB.vue')
},
{ 
  path: '/crudCausasB', component: () => import('../views/Postcosecha/controlCalidadB/causasCalidadB.vue')
},
//-------------------------------------------------------------------------------------------------- PREFRÍO
{ 
  path: '/recepcionCosecha', component: () => import('../views/Postcosecha/Prefrio/recepcionCosecha.vue')
},
{ 
  path: '/salidaPrefrio', component: () => import('../views/Postcosecha/Prefrio/salidaPrefrio.vue')
},
{ 
  path: '/devolucionCajaPrefrio', component: () => import('../views/Postcosecha/Prefrio/devolucionCaja.vue')
},
{ 
  path: '/correccionTallosCaja', component: () => import('../views/Postcosecha/Prefrio/correccionTallos.vue')
},
{ 
  path: '/tableroPrefrio', component: () => import('../views/Postcosecha/Prefrio/tableroPrefrio.vue')
},
{ 
  path: '/transaccionesPreFrio', component: () => import('../views/Postcosecha/Prefrio/transaccionesPrefrio.vue')
},
{ 
  path: '/trazabilidadPrefrio', component: () => import('../views/Postcosecha/Prefrio/trazabilidadPrefrio.vue')
},
{ 
  path: '/tableroSalidasPrefrio', component: () => import('../views/Postcosecha/Prefrio/tableroSalidasPrefrio.vue')
},
{ 
  path: '/tableroLineasPrefrio', component: () => import('../views/Postcosecha/Prefrio/tableroLineas.vue')
},
//-------------------------------------------------------------------------------------------------------- CUARTO FRÍO
{ 
  path: '/generarEtiquetasGuarde', component: () => import('../views/Postcosecha/cuartoFrio/etiquetasGuarde.vue')
},
{ 
  path: '/ingresoCuartoFrio', component: () => import('../views/Postcosecha/cuartoFrio/ingresoCuartoFrio.vue')
},
{ 
  path: '/salidaCuartoFrio', component: () => import('../views/Postcosecha/cuartoFrio/salidaCuartoFrio.vue')
},
{ 
  path: '/tableroCuartoFrio', component: () => import('../views/Postcosecha/cuartoFrio/tableroCuartoFrio.vue')
},
{ 
  path: '/etiquetasSinUsar', component: () => import('../views/Postcosecha/cuartoFrio/etiquetasSinUsar.vue')
},
{ 
  path: '/devolucionGuarde', component: () => import('../views/Postcosecha/cuartoFrio/devolucionGuarde.vue')
},
{ 
  path: '/transaccionesFecha', component: () => import('../views/Postcosecha/cuartoFrio/transaccionesFecha.vue')
},
{
  path: '/trazabilidadCajaPostcosecha', component: () => import('../views/Postcosecha/cuartoFrio/trazabilidadCajaPostcosecha.vue')
},
{ 
  path: '/tableroB', component: () => import('../views/Postcosecha/cuartoFrio/tableroB.vue')
},
{ 
  path: '/despachoBCuartoFrio', component: () => import('../views/Postcosecha/cuartoFrio/despachoB.vue')
},
{ 
  path: '/controlSalidaDespacho', component: () => import('../views/Postcosecha/cuartoFrio/controlSalidaDespacho.vue')
},
{ 
  path: '/ingresoCuartoFrioBunchesB', component: () => import('../views/Postcosecha/controlCalidadB/BunchesB/respaldoBunchesB.vue')
},
{ 
  path: '/recibirBunchesBCuartoFrio', component: () => import('../views/Postcosecha/cuartoFrio/recepcionBunchesB.vue')
},
{ 
  path: '/cuadroComparativoBunchesBCausas', component: () => import('../views/Postcosecha/controlCalidadB/BunchesB/cuadroComparativo.vue')
},
//--------------------------------------------------------------------------------------------------------- VISTAS PARA DIRECTORIO
{ 
  path: '/directorioColaborador', component: () => import('../views/Directorio/directorioColaborador.vue')
},
{ 
  path: '/directorioTelefonico', component: () => import('../views/Directorio/directorioTelefonico.vue')
},
//--------------------------------------------------------------------------------------------------------- VISTAS PARA IT
{ 
  path: '/edicionIt', component: () => import('../views/IT/Edicion/edicionIt.vue')
},
{ 
  path: '/registroFuncionamientoIt', component: () => import('../views/IT/registro/registroFuncionamientoIt.vue')
},
{ 
  path: '/resumenFuncionalidadIt', component: () => import('../views/IT/Reportes/resumenFuncionalidad.vue')
},
//------------------------------------------------------VISTAS PARA VENTA
{ 
  path: '/ventaPedido', component: () => import('../views/Venta/ventaPedido.vue')
},
{ 
  path: '/crearDespachoB', component: () => import('../views/Postcosecha/crearDespachoB.vue')
},
];

const router = new VueRouter({
  mode: 'history',
  routes
})


export default router
