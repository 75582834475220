import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    menu: true,
    autenticado:false,
    nombre:'',
    idRol:'',
    usuario:'',
    token:null,
    imagenPerfil:null,
    aprobar:0,
  },
  mutations: {
    setMenu(state, value) {
      state.menu = value;
    },
    setAprobar(state, value) {
      state.aprobar = value;
    },
    setToken(state, value) {
      state.token = value;
    },
    setAutenticado(state, value) {
      state.autenticado = value;
    },
    setReiniciarValores(state) {
      state.autenticado = true;
      state.menu = true;
    },
    setIniciarMenu(state) {
      state.menu = true;
    },
    setnombreUsuario(state, value) {
      state.nombre = value;
    },
    setRol(state, value) {
      state.idRol = value;
    },
    setUsuario(state, value) {
      state.usuario = value;
    },
    setImagen(state, value) {
      state.imagenPerfil = value;
    }
  },
  actions: {
    actalizarEstadoMenu({ commit }, value) {
      commit('setMenu', value);
    },
    actalizarEstadoAprobar({ commit }, value) {
      commit('setAprobar', value);
    },
    actalizarEstadoAuth({ commit }, value) {
      commit('setAutenticado', value);
    },
    actualizarNombreUsuario({ commit }, value) {
      commit('setnombreUsuario', value);
    },
    actualizarRol({ commit }, value) {
      commit('setRol', value);
    },
    actualizarUsuario({ commit }, value) {
      commit('setUsuario', value);
    },
    actualizarToken({ commit }, value) {
      commit('setToken', value);
    },
    actualizarImagen({ commit }, value) {
      commit('setImagen', value);
    }
  },
  plugins: [createPersistedState()]
});

export default store;